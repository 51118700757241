
<template>
  <div>
    <header1Component
      :circleActive="1"
      :detailActive="1"
      :loopCircleSmall="4"
      :circleSmall="1"
      :changeCircle="0"
      :changeTitle="0"
      titleHeader="หัวข้อ : 2.รายละเอียดและข้อเสนอโครงการ"
    />
    <div class="container">
      <div class="card2">
        <div class="title conditionCard2">
          ผลผลิต (Output)/ ผลลัพธ์ (Outcome) <font color="red">*</font>
        </div>

        <div>
          <div
            class="flex flex-wrap items-center justify-center"
            v-for="(item, index) in productOutputOutcome"
            :key="index"
          >
            <div class="lg:w-1/2">
              <div class="conditionCard2">
                <div class="contentActive pb-2">
                  ผลผลิต <font color="red">*</font>
                </div>
                <textarea v-model="productOutputOutcome[index].product"></textarea>
              </div>
            </div>
            <div class="lg:w-1/2">
              <div class="conditionCard2">
                <div class="contentActive">
                  ตัวชี้วัดผลผลิต <font color="red">*</font>
                </div>

                <div style="margin-bottom: 16px">
                  <div class="content">
                    ตัวชี้วัดเชิงปริมาณ <font color="red">*</font>
                  </div>
                  <input type="text" placeholder="กรอก" v-model="productOutputOutcome[index].quantitativeIndicators" />
                </div>

                <div style="margin-bottom: 16px">
                  <div class="content">
                    ตัวชี้วัดเชิงคุณภาพ <font color="red">*</font>
                  </div>
                  <input type="text" placeholder="กรอก" v-model="productOutputOutcome[index].qualitativeIndicators" />
                </div>

                <div style="margin-bottom: 16px">
                  <div class="content">
                    ตัวชี้วัดเชิงเวลา <font color="red">*</font>
                  </div>
                  <input type="text" placeholder="กรอก" v-model="productOutputOutcome[index].timeIndicators" />
                </div>

                <div style="margin-bottom: 16px">
                  <div class="content">
                    ตัวชี้วัดเชิงต้นทุน <font color="red">*</font>
                  </div>
                  <input type="text" placeholder="กรอก" v-model="productOutputOutcome[index].costIndicators" />
                </div>
              </div>
            </div>

            <div
              v-if="index === 0"
              class="
                flex flex-row
                items-center
                justify-center justify-items-center
              "
              align="center"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="margin-right: 16px"
                @click="addForm()"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="plus"
                  color="white"
                />
              </div>

              <div class="titleCircle flex items-center">
                เพิ่มผู้ร่วมงานหลัก
              </div>
            </div>

            <div
              v-else
              class="
                flex flex-row
                items-center
                justify-center justify-items-center
              "
              align="center"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="margin-right: 16px; background-color: red"
                @click="deleteForm(index)"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>

              <div class="titleCircle flex items-center">ลบผู้ร่วมงานหลัก</div>
            </div>
          </div>
        </div>

        <hr />

        <div class="title conditionCard2" style="margin-bottom: 26px">
          หัวหน้าโครงการ
        </div>

        <div class="conditionCard2" style="margin-bottom: 30px">
          <table>
            <tr align="center">
              <th class="tr">ลำดับ</th>
              <th class="tr">ชื่อ-นามสกุล</th>
              <th class="tr">เลขประจำตัวประชาชน</th>
              <th class="tr">ตำแหน่งในโครงการ</th>
              <th class="tr">อีเมล</th>
              <th class="tr">โทรศัพท์</th>
              <th class="tr">โทรสาร</th>
              <th class="tr">โทรศัพท์มือถือ</th>
            </tr>

            <tr>
              <td colspan="9">
                <hr
                  style="
                    margin: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    border: 1px solid #c4c4c4;
                  "
                />
              </td>
            </tr>

            <tr align="center">
              <td class="td">1</td>
              <td class="td">{{ projectLeader.firstName }}</td>
              <td class="td">{{ projectLeader.lastName }}</td>
              <td class="td">{{ projectLeader.cardNumber }}</td>
              <td class="td">{{ projectLeader.email }}</td>
              <td class="td">{{ projectLeader.phone }}</td>
              <td class="td">{{ projectLeader.phoneExtention }}</td>
              <td class="td">{{ projectLeader.mobilePhone }}</td>
            </tr>
          </table>
        </div>

        <div class="title conditionCard2" style="margin-bottom: 26px">
          ผู้ร่วมงานหลัก
        </div>

        <div class="conditionCard2" style="margin-bottom: 30px">
          <table>
            <tr align="center">
              <th class="tr">ลำดับ</th>
              <th class="tr">ชื่อ-นามสกุล</th>
              <th class="tr">เลขประจำตัวประชาชน</th>
              <th class="tr">ตำแหน่งในโครงการ</th>
              <th class="tr">อีเมล</th>
              <th class="tr">โทรศัพท์</th>
              <th class="tr">โทรสาร</th>
              <th class="tr">โทรศัพท์มือถือ</th>
            </tr>

            <tr>
              <td colspan="9">
                <hr
                  style="
                    margin: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    border: 1px solid #c4c4c4;
                  "
                />
              </td>
            </tr>

            <tr
              align="center"
              v-for="(item, index) in mainParticipant"
              :key="index"
            >
              <td class="td">{{ index + 1 }}</td>
              <td class="td">{{ item.firstName }}</td>
              <td class="td">{{ item.lastName }}</td>
              <td class="td">{{ item.cardNumber }}</td>
              <td class="td">{{ item.email }}</td>
              <td class="td">{{ item.phone }}</td>
              <td class="td">{{ item.phoneExtention }}</td>
              <td class="td">{{ item.mobilePhone }}</td>
            </tr>
          </table>
        </div>

        <div class="title conditionCard2" style="margin-bottom: 26px">
          ผู้ประสานงาน
        </div>

        <div class="conditionCard2" style="margin-bottom: 30px">
          <table>
            <tr align="center">
              <th class="tr">ลำดับ</th>
              <th class="tr">ชื่อ-นามสกุล</th>
              <th class="tr">เลขประจำตัวประชาชน</th>
              <th class="tr">ตำแหน่งในโครงการ</th>
              <th class="tr">อีเมล</th>
              <th class="tr">โทรศัพท์</th>
              <th class="tr">โทรสาร</th>
              <th class="tr">โทรศัพท์มือถือ</th>
            </tr>

            <tr>
              <td colspan="9">
                <hr
                  style="
                    margin: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    border: 1px solid #c4c4c4;
                  "
                />
              </td>
            </tr>

            <tr align="center">
              <td class="td">1</td>
              <td class="td">{{ coordinator.firstName }}</td>
              <td class="td">{{ coordinator.lastName }}</td>
              <td class="td">{{ coordinator.cardNumber }}</td>
              <td class="td">{{ coordinator.email }}</td>
              <td class="td">{{ coordinator.phone }}</td>
              <td class="td">{{ coordinator.phoneExtention }}</td>
              <td class="td">{{ coordinator.mobilePhone }}</td>
            </tr>
          </table>
        </div>

        <div class="flex flex-row">
          <div
            class="w-1/2 flex flex-start justify-start"
            style="margin-left: 72px; margin-top: 81px"
          >
            <button class="buttonPrevious" @click="createRequest()">
              ก่อนหน้า
            </button>
          </div>

          <div
            class="w-1/2 flex items-end justify-end"
            style="margin-right: 72px; margin-top: 81px"
          >
            <button class="buttonNext" @click="updateRequest()">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useRequest from "../../hooks/useRequest";
import header1Component from "@/components/capital/header1Component";
export default {
  components: {
    header1Component,
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      useRequest.getProjectId(this.id).then((response) => {
        if (response.data.productOutputOutcome.length > 0) {
            this.productOutputOutcome = response.data.productOutputOutcome;
        }
        this.projectLeader = response.data.projectLeader;
        this.mainParticipant = response.data.mainParticipant;
        this.coordinator = response.data.coordinator;
      });
    },
    addForm() {
      this.productOutputOutcome.push({
        product: null,
        quantitativeIndicators: null,
        qualitativeIndicators: null,
        timeIndicators: null,
        costIndicators: null,
      });
    },
    deleteForm(id) {
        this.productOutputOutcome.splice(id, 1);
    },
    updateRequest() {
        useRequest.updateProject(this.id, {productOutputOutcome: this.productOutputOutcome}).then(response => {
            console.log(response.data);
        });
    }
  },
  setup() {
    const productOutputOutcome = ref([
      {
        product: null,
        quantitativeIndicators: null,
        qualitativeIndicators: null,
        timeIndicators: null,
        costIndicators: null,
      },
    ]);
    const projectLeader = ref({});
    const mainParticipant = ref([]);
    const coordinator = ref({});
    return {
      productOutputOutcome,
      projectLeader,
      mainParticipant,
      coordinator,
    };
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #242424;
  margin-bottom: 10px;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-bottom: 10px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

button {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}

.buttonPrevious {
  width: 195px;
  height: 50px;
  color: #573c81;
  background: #ffffff;
  border: 1px solid #573c81;
  box-sizing: border-box;
  border-radius: 5px;
}

textarea {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 360px;
  padding-left: 18px;
  padding-top: 10px;
}

table {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.tr {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #242424;

  padding-top: 24px;
  padding-bottom: 15px;
  padding-left: 10px;
}

.td {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;

  color: #242424;

  padding-top: 16px;
  padding-bottom: 24px;
}

.circle {
  width: 40px;
  height: 40px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 36px;
}

.titleCircle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}
</style>